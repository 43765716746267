import React, { Suspense, lazy } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import RouteLoader from '../utils/RouteLoader'
import Route from './Route'

const AnswerSplash = withRouter(lazy(() => import('../pages/Splash')))
const Home = withRouter(lazy(() => import('../pages/Home')))
const Question = withRouter(lazy(() => import('../pages/Question')))
const Prelude = withRouter(lazy(() => import('../pages/Prelude')))
const DialoguesComponent = withRouter(lazy(() => import('../dialogues')))
const DialoguesAssessments = withRouter(lazy(() => import('../dialoguesV2')))
const DialoguesExample = withRouter(lazy(() => import('../dialoguesV2/DialoguesExample')))
const DialoguesWelcome = withRouter(lazy(() => import('../dialoguesV2/DialoguesWelcome')))
const SelectionPanel = withRouter(lazy(() => import('../pages/SelectionPanel')))
const PageNotFound = withRouter(lazy(() => import('../pages/PageNotFound')))
const WorkWorldTool = withRouter(lazy(() => import('../workWorldTool')))
const WorkWorldToolAfter = withRouter(
  lazy(() => import('../workWorldTool/components/WorkWorldToolAfter')),
)

export default function Routes() {
  return (
    <Suspense fallback={<RouteLoader />}>
      <Switch>
        <Route path="/" exact component={Prelude} isPrivate />
        <Route path="/selection-panel" exact component={SelectionPanel} isPrivate />
        <Route path="/dialogues" exact component={DialoguesWelcome} isPrivate />
        <Route path="/dialogues-assessment" exact component={DialoguesAssessments} isPrivate />
        <Route path="/dialogues-example" exact component={DialoguesExample} isPrivate />
        <Route path="/dialogues-old" exact component={DialoguesComponent} isPrivate />
        <Route path="/home" exact component={Home} isPrivate />
        <Route path="/question" exact component={Question} isPrivate />
        <Route path="/splash" exact component={AnswerSplash} isPrivate />
        <Route path="/work-world-tool-after" exact component={WorkWorldToolAfter} isPrivate />
        <Route path="/work-world-tool" exact component={WorkWorldTool} isPrivate />
        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  )
}
