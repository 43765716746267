import Keycloak from 'keycloak-js'

const _kc = new Keycloak({
  realm: 'ias',
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: 'questionary',
})

const clearLocalStorage = () => {
  localStorage.removeItem('userResponses')
  localStorage.removeItem('isFirstAcess')
}

const doLogin = () => {
  clearLocalStorage()
  _kc.login()
}

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
    })
    .then((authenticated) => {
      if (authenticated) onAuthenticatedCallback()
      else doLogin()
    })
}

const doLogout = () => {
  clearLocalStorage()
  _kc.logout()
}

const getToken = () => _kc.token

const getRefreshToken = () => _kc.refreshToken

const isLoggedIn = () => !!_kc.authenticated

const getUsername = () => _kc.tokenParsed?.preferred_username

const getUserId = () => _kc.tokenParsed?.sub

const getName = () => _kc.tokenParsed?.name

const forceLogout = () => {
  doLogout({ redirectUri: 'https://plataformafarol.institutoayrtonsenna.org.br' })
  localStorage.removeItem('user_info')
  localStorage.removeItem('codExaminee')
  localStorage.removeItem('userResponses')
  localStorage.removeItem('isFirstAcess')
}

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getUsername,
  getName,
  forceLogout,
  getRefreshToken,
  getUserId,
}

export default UserService
